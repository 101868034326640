import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RegisterPage from './RegisterPage';
import CongratulationPage from './CongratulationPage';

const App = () => {
  const [username, setUsername] = useState('');

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<RegisterPage setUsername={setUsername} />}
        />
        <Route
          path="/congratulations"
          element={<CongratulationPage username={username} />}
        />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css';

const RegisterPage = ({ setUsername }) => {
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleRegister = () => {
    if (name.trim() === '') {
      alert('Please enter your name!');
      return;
    }
    setUsername(name);
    navigate('/congratulations');
  };      

  return (
    <div className="register-page">
      <div className="form-container">
        <h1>Register a New Car</h1>
        <input
          type="text"
          placeholder="Enter your username"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br>
        </br>
        <br></br>
        <button onClick={handleRegister}>Register</button>
      </div>
    </div>
  );
};

export default RegisterPage;

import React from 'react';
import './CongratulationPage.css';
import congratulationImage from './assets/images/cong.png';



const CongratulationsPage = ({ username }) => {
  return (
    <div className="congratulations-page">
      <div className="congratulations-container">
        
        {/* Congratulations Image */}
        <div className="congratulations-image">
          <img src={congratulationImage} alt="Congratulations" />
        </div>

        

        {/* Username Text Container */}
        <div className="username-container">
          <h2><span className="username">{username}</span></h2>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsPage;
